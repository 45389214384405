<template>
  <v-dialog v-model="dialog" width="500">
    <v-card aut-event-input>
      <DialogTitle @close="dialog = false" title="Create Event" />
      <v-card-text>
        <v-form ref="eventForm">
          <v-text-field
            filled
            dense
            placeholder="Add Title"
            v-model="eventObj.name"
            :rules="[mandatoryRule]"
            aut-title
          >
          </v-text-field>
          <v-row dense>
            <v-col cols="6" class="py-0">Start</v-col>
            <v-col cols="6" class="py-0">End</v-col>
            <v-col class="pt-0">
              <TimeInput
                aut-start
                v-model="startTime"
                :disabled="timeDisabled"
                label="Start"
                :rules="[mandatoryRule]"
              />
            </v-col>
            <v-col class="pt-0">
              <TimeInput
                aut-end
                v-model="endTime"
                :disabled="timeDisabled"
                label="End"
                :rules="[mandatoryRule]"
              />
            </v-col>
          </v-row>
          <v-row dense no-gutters>
            <v-spacer></v-spacer>
            <v-col class="pa-0" cols="3">
              <v-switch
                aut-all-day
                v-model="timeDisabled"
                class="pa-0"
                dense
                inset
                :hide-details="true"
                label="All Day"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea
                :rows="2"
                auto-grow
                filled
                placeholder="Add Details"
                v-model="eventObj.details"
                aut-details
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dense text color="secondary" @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn aut-create dense color="secondary" @click="handleCreate">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { dialogMixin } from "@/components/mixin.js";
import { rules } from "@/rules.js";
import { isEqual } from "lodash";
import { clone } from "@/util";
import { isAfter, parseISO, setHours, setMinutes } from "date-fns";
export default {
  name: "EventInput",
  mixins: [dialogMixin],
  components: {
    TimeInput: () => import("./TimeInput.vue"),
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    event: Object,
  },
  data() {
    return {
      eventObj: this.event || {},
      timeMenu: false,
      time: "",
      timeDisabled: false,
    };
  },
  watch: {
    event(value) {
      if (!isEqual(value, this.eventObj)) {
        this.eventObj = clone(this.event || {});
      }
    },
    timeDisabled(isDisabled) {
      if (isDisabled) {
        const start = parseISO(this.eventObj.start);
        const end = parseISO(this.eventObj.end);

        this.startTime = setHours(setMinutes(start, 0), 0).toISOString();
        this.endTime = setHours(setMinutes(end, 59), 23).toISOString();
      }
    },
  },
  computed: {
    dialog: {
      get() {
        if (!this.value) {
          this.$refs?.eventForm?.resetValidation();
        }
        return this.value;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
    startTime: {
      get() {
        return this.eventObj?.start || "";
      },
      set(time) {
        const startTime = parseISO(time);
        const endTime = parseISO(this.endTime);

        if (isAfter(startTime, endTime)) {
          this.endTime = time;
        }

        this.eventObj.start = time;
      },
    },
    endTime: {
      get() {
        return this.eventObj?.end || "";
      },
      set(time) {
        this.eventObj.end = time;
      },
    },
    date() {
      const date = this.eventObj?.start || "";
      const formatDate = this.$options.filters.date;
      return formatDate(date, { date_format: "EEEE',' do MMM" });
    },
    mandatoryRule() {
      return rules.mandatory();
    },
  },
  methods: {
    handleCreate() {
      const isValid = this.$refs.eventForm.validate();

      if (isValid) {
        this.$emit("update", this.eventObj);
      }
    },
  },
};
</script>
